<template>
  <!--====================  project slider area ====================-->
  <div class="project-slider-area grey-bg section-space--inner--60">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!-- section title -->
          <div class="section-title-area text-center">
            <h2 class="section-space--bottom--50">
              {{ title }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="latest-project-slider">
            <div class="latest-project-slider__container-area">
              <div class="swiper-container latest-project-slider__container">
                <div class="swiper-wrapper latest-project-slider__wrapper">
                  <swiper :options="swiperOption">
                    <div
                      class="swiper-slide latest-project-slider__single-slide"
                      v-for="project in data"
                      :key="project.id"
                    >
                      <div class="row row-30 align-items-center">
                        <div class="col-lg-5">
                          <div class="image">
                            <img
                              style="padding-left: 70px;"
                              :src="project.image"
                              class="img-fluid"
                              alt="thumbnail"
                            />
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div
                            class="content"
                            style="width: 70%;margin-left: 10%;"
                          >
                            <!--<h3 class="count">{{ project.count }}</h3>-->
                            <h2 class="title">{{ project.title }}</h2>
                            <p class="desc">{{ project.desc }}</p>
                            <p class="desc">{{ project.desc2 }}</p>
                            <a :href="project.bLink" v-if="project.bShow">
                              <button
                                type="submit"
                                style="margin: auto;"
                                class="btn ht-btn--round"
                              >
                                Ver más
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper>
                </div>
              </div>
              <div
                class="ht-swiper-button-prev ht-swiper-button-prev-16 ht-swiper-button-nav d-none d-lg-block"
              >
                <i class="ion-ios-arrow-left"></i>
              </div>
              <div
                class="ht-swiper-button-next ht-swiper-button-next-16 ht-swiper-button-nav d-none d-lg-block"
              >
                <i class="ion-ios-arrow-forward"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====================  End of project slider area  ====================-->
</template>

<script>
export default {
  props: ["data", "title"],
  data() {
    return {
      swiperOption: {
        speed: 1500,
        loop: true,
        autoplay: false,
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
          nextEl: ".ht-swiper-button-next",
          prevEl: ".ht-swiper-button-prev"
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: -10%;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: auto;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom !important;
  background-color: #e94e1b;
}
</style>