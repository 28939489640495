<template>
  <div class="main-container">
    <Header />

    <AboutSection addClass="grey-bg" />

    <div>
      <img
        src="/assets/img/about/3.png"
        style="width: 100%;padding: 0px;"
      />
    </div>

    <!--<Policy :policyData="data.policy" />-->

    <!--Our bases section-->
    <LatestProject :data="data.bases" :title="'En qué nos basamos'" />

    <Experience :experience="data.experience" />

    <!--<FunFact />

    <TeamMember :teamData="data.team" />

    <Testimonial />-->

    <div
      class="breadcrumb-area bg-img"
      style="background-image: url(assets/img/about/4.png);width: -webkit-fill-available;background-size: contain;"
    >
      <div class="container">
        <div class="row">
          <div class="col-7">
            <h2
              style="color:#fff;font-style:italic;font-size:22px;padding-top: 15%;"
            >
              Conectamos con tus emociones, conectamos con tus necesidades,
              conectamos con tus experiencias, al alcance de un click.
            </h2>
          </div>
        </div>
      </div>
    </div>

    <BrandCarousel addClass="grey-bg" />

    <Footer />

    <OffCanvasMobileMenu />
    <!-- WhatsApp Bottom -->
    <div id="whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=+584142698744&text=¡Hola!%20Quiero%20más%20información%20acerca%20de%20sus%20servicios."
        title="Escríbenos por Whastapp"
        data-tap="tooltip"
      >
        <i
          class="fa fa-whatsapp"
          style="color: #ffffff;"
          aria-hidden="true"
        ></i>
      </a>
    </div>

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="100px" right="10px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import Breadcrumb from "../components/Breadcrumb";
import Policy from "../components/Policy";
import Experience from "../components/Experience";
import LatestProject from "../components/sections/LatestProject";
import FunFact from "../components/FunFact";
import AboutSection from "../components/sections/AboutSection";
import TeamMember from "../components/sections/TeamMember";
import Testimonial from "../components/Testimonial";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

import data from "../data/about.json";
export default {
  components: {
    Header,
    Breadcrumb,
    AboutSection,
    LatestProject,
    Policy,
    Experience,
    FunFact,
    TeamMember,
    Testimonial,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  },
  data() {
    return {
      data,
      items: [
        {
          text: "Home",
          to: "/"
        },
        {
          text: "About Us",
          active: true
        }
      ]
    };
  }
};
</script>

<style lang="scss" >
.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: -10%;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: auto;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom !important;
  background-color: #e94e1b;
}
#whatsapp {
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 20px;
  display: block;
  color: #ffffff;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  background-color: #00e676;
}
@media only screen and (max-width: 479px) {
  .scroll-top {
    right: 10px !important;
    bottom: 80px !important;
  }
  #whatsapp {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #202020;
  font-weight: 600;
  line-height: 35px !important;
}
.breadcrumb-area::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #00000000;
  opacity: 0.75;
  z-index: -1;
}
</style>

