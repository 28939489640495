<template>
  <div class="service-slider-title-area grey-bg section-space--inner--60">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title-area text-center">
            <h2 class="section-space--bottom--50">
              {{ title }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="team-member-wrapper">
            <div class="row">
              <!--Team member start-->
              <div
                class="col-lg-3 col-sm-6 col-12 section-space--bottom--30"
                v-for="teamMember in teamData.teamMemberData"
                :key="teamMember.id"
              >
                <div class="team">
                  <div class="image">
                    <img :src="teamMember.image" alt="team member image" />
                  </div>
                  <div class="content">
                    <h3 class="title">{{ teamMember.name }}</h3>
                    <span>{{ teamMember.designation }}</span>
                    <a href="mailto:info@example.com" class="email">{{
                      teamMember.email
                    }}</a>
                  </div>
                </div>
              </div>
              <!--Team member end-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["teamData", "title"]
};
</script>